<template>
  <div class="admin-create-rota">
    <loading-screen :is-loading="isSaving"></loading-screen>

    <!-- Header -->
    <div class="page-header">
      <h1 class="page-title">
        <a-button type="default" style="display: inline; margin-right: 15px" @click.prevent="goBack" icon="left">
        </a-button>

        <admin-org-indicator></admin-org-indicator>

        {{ title }}
      </h1>
    </div>
    <!-- / Header -->

    <!-- Description -->
    <a-alert style="margin-bottom: 25px"
      message="A user is available at any time if there are no rota entries. Rotas help match working patterns.">
    </a-alert>
    <!-- / Description -->

    <!-- Loading -->
    <div class="loader" v-if="isLoading">
      <a-spin></a-spin>
    </div>
    <!-- / Loading -->

    <!-- Loaded -->
    <div v-if="!isLoading">
      <!-- Weeks Input -->
      <div v-if="numberOfWeeks > 1">
        <div class="title">
          <h3>Weeks</h3>
        </div>

        <div class="form margin-bottom">
          <a-checkbox-group :options="weekOptions" v-model="selectedWeeks" />
          <!-- {{ numberOfWeeks }} -->
          <!-- {{ timetableWeeksAlias }} -->
        </div>
      </div>
      <!-- / Weeks Input -->

      <div class="title">
        <h3>Days</h3>
      </div>

      <!-- Day Input -->
      <div class="form margin-bottom">
        <a-checkbox-group :options="dayOptions" v-model="selectedDays" />
      </div>
      <!-- / Day Input -->

      <div class="title">
        <h3>Periods</h3>
      </div>

      <!-- Period -->
      <div class="form margin-bottom-small" v-for="(period, i) in periods" :key="i">
        <div class="period-wrapper">
          <div class="period">
            <div class="left">
              <b class="range-title">
                Period {{ i + 1 }} <br />
                <span>{{ formatter(period.range[0]) }} -
                  {{ formatter(period.range[1]) }}</span>
              </b>

              <a-slider v-model="period.range" :tip-formatter="(value) => formatter(value)" :min="0" range :max="1440"
                :step="5" />
            </div>
            <div class="right">
              <a-popconfirm title="Are you sure?" ok-text="Yes" cancel-text="No" @confirm="deletePeriod(i)"
                v-if="periods.length > 1">
                <a-button type="danger" icon="delete"></a-button>
              </a-popconfirm>
            </div>
          </div>
        </div>
      </div>
      <!-- / Period -->

      <div>
        <a-button icon="plus" @click.prevent="addPeriod">Add Period</a-button>
      </div>

      <a-button @click.prevent="save" class="btn-rounded save-button" size="large" type="primary">Save</a-button>
    </div>
    <!-- / Loaded -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import presences from "../../../../../api/presences";
import availability from "../../../../../api/availability";
const _ = require("lodash");
import rotaHelpers from "../../../../../helpers/rota";
import LoadingScreen from "../../../../../components/LoadingScreen.vue";

export default {
  data() {
    return {
      isLoadingPresence: false,
      presence: null,

      isSaving: false,

      selectedDays: [],
      selectedWeeks: [0],
      periods: [
        {
          range: [540, 1005],
          //   startTime: 540, // 09:00
          //   endTime: 1005, // 16:45
        },
      ],
    };
  },
  components: { LoadingScreen },
  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    } else {
      this.loadPresence();
    }
  },
  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId",
    }),

    numberOfWeeks() {
      if (this.selectedOrganisation['content']['$v']['timetableWeeksNullable']) {
        return this.selectedOrganisation['content']['$v']['timetableWeeksNullable'];
      }
      return 1;
    },

    timetableWeeksAlias() {
      if (this.selectedOrganisation['content']['$v']['timetableWeeksAlias']) {
        return this.selectedOrganisation['content']['$v']['timetableWeeksAlias'].split(',');
      }
      return ['Week 1'];
    },

    dayOptions() {
      return rotaHelpers.listDayOptions();
    },

    weekOptions() {
      let options = [];

      for (let i  = 0; i < this.numberOfWeeks; i++) {
        options.push({
          value: i,
          label: this.timetableWeeksAlias[i]
        });
      }

      return options;
    },

    isLoading() {
      return this.isLoadingPresence || this.isLoadingSchedules;
    },

    title() {
      if (this.isLoadingPresence) {
        return "Loading...";
      }
      if (this.presence) {
        return "New Rota For " + this.presence.displayName;
      }
      return null;
    },

    presenceId() {
      return this.$route.params.presenceId;
    },
  },
  methods: {
    goBack() {
      this.$router.push(
        "/admin/responders/availability/" + this.presenceId + "/rota"
      );
    },

    formatter(value) {
      let hr = Math.floor(Number(value) / 60);
      let min = value % 60;

      return String(hr).padStart(2, "0") + ":" + String(min).padStart(2, "0");
    },

    deletePeriod(i) {
      this.periods = _.filter(this.periods, (period, j) => {
        return j !== i;
      });
    },

    addPeriod() {
      this.periods.push({
        range: [540, 1005],
      });
    },

    loadPresence() {
      let vm = this;
      vm.isLoadingPresence = true;
      vm.presence = null;
      presences
        .getPresence(this.tenantId, this.presenceId)
        .then((r) => {
          vm.isLoadingPresence = false;
          vm.presence = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingPresence = false;
          vm.$message.error("Error loading user");
          vm.goBack();
        });
    },

    loadSchedules() {
      let vm = this;
      vm.isLoadingSchedules = true;
      vm.schedules = [];
      availability
        .getSchedulesForPresence(this.tenantId, this.presenceId)
        .then((r) => {
          vm.schedules = r.data;
          vm.isLoadingSchedules = false;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingSchedules = false;
          vm.$message.error("Error loading rota");
          vm.goBack();
        });
    },

    createSchedule() {
      this.$router.push(
        "/admin/responders/availability/" + this.presenceId + "/rota/create"
      );
    },

    save() {
      let vm = this;
      vm.isSaving = true;

      let params = {
        ownerId: vm.selectedOrganisation.id,
        presenceId: vm.presenceId,
        selectedDays: _.sum(this.selectedDays),
        selectedWeeks: this.selectedWeeks,
        periods: _.map(this.periods, (period) => {
          return {
            startTime: this.formatter(period.range[0]) + ':00',
            endTime: this.formatter(period.range[1]) + ':00'
          };
        }),
      };
      availability
        .addSchedule(this.tenantId, params)
        .then(() => {
          vm.isSaving = false;
          vm.$message.success("Rota saved successfully");
          vm.goBack();
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          vm.$message.error("Error saving rota");
        });
    },
  },
};
</script>

<style lang="scss">
.admin-create-rota {
  padding-bottom: 20px;

  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .ant-table {
    background: #fff !important;
  }

  .button-margin-left {
    margin-left: 15px;
  }

  .title {
    margin-bottom: 20px;
  }

  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;

    &.margin-bottom {
      margin-bottom: 30px;
    }

    &.margin-bottom-small {
      margin-bottom: 15px;
    }
  }

  .save-button {
    margin-top: 30px;
  }

  .period-wrapper {
    padding-top: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;

    &:last-child {
      border-bottom: none !important;
    }

    .period {
      //   padding-top: 35px;
      display: flex;

      .left {
        flex-grow: 1;
        padding-left: 10px;
        padding-right: 50px;
        //   display: flex;
        //   align-items: center;
        //   justify-items: center;

        .range-title {
          display: block;
          margin-bottom: 50px;

          span {
            font-weight: 400;
            display: block;
            margin-top: 5px;
          }
        }
      }

      .right {
        flex-shrink: 1;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>